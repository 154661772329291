import { useAuth0 } from "@auth0/auth0-react";
import LoggedOut from './LoggedOut.js';
import { useEffect, useState } from 'react';

function LogOutWrapper() {
    const { isLoading, error, logout, isAuthenticated } = useAuth0();
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                (async () => {
                    await logout();
                    setIsLoggedOut(true);
                })();
            } else {
                setIsLoggedOut(true); // Directly set to logged out if not authenticated
            }
        }
    }, [isLoading, isAuthenticated, logout]);

    if (error) {
        return <div>ERROR: {error.message}</div>;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isLoggedOut) {
        return <LoggedOut />;
    }

    return null;
}

export default LogOutWrapper;
