
const amazonAppId = "amzn1.sp.solution.a42891ce-e105-431c-bc8a-1b6cfd0f1681";

export function buildAmazonOAuthUrl(marketplaceId, draft, stateStr) {
    let url = getSellerCentralURL(marketplaceId);
    let response = url + "/apps/authorize/consent?application_id=" + amazonAppId;
    response = response + "&state=" + stateStr
    if (draft) {
        response = response + "&version=beta";
    }
    return response
}

function getSellerCentralURL(marketplaceId){
    for (const category of amazonMarketplaces) {
        for (const marketplace of category.marketplaces) {
            if (marketplace.marketplaceId === marketplaceId) {
                return marketplace.sellerCentralURL;
            }
        }
    }
    throw new Error("Invalid Marketplace ID");
}


const amazonMarketplaces = [
    {
        category: "North America",
        marketplaces: [
            {
                country: "Canada",
                marketplaceId: "A2EUQ1WTGCTBG2",
                countryCode: "CA",
                sellerCentralURL: "https://sellercentral.amazon.ca",
                flagURL: "https://flagcdn.com/ca.svg"
            },
            {
                country: "United States of America",
                marketplaceId: "ATVPDKIKX0DER",
                countryCode: "US",
                sellerCentralURL: "https://sellercentral.amazon.com",
                flagURL: "https://flagcdn.com/us.svg"
            },
            {
                country: "Mexico",
                marketplaceId: "A1AM78C64UM0Y8",
                countryCode: "MX",
                sellerCentralURL: "https://sellercentral.amazon.com.mx",
                flagURL: "https://flagcdn.com/mx.svg"
            },
            {
                country: "Brazil",
                marketplaceId: "A2Q3Y263D00KWC",
                countryCode: "BR",
                sellerCentralURL: "https://sellercentral.amazon.com.br",
                flagURL: "https://flagcdn.com/br.svg"
            },
        ]
    },
    {
        category: "Europe",
        marketplaces: [
            {
                country: "Spain",
                marketplaceId: "A1RKKUPIHCS9HS",
                countryCode: "ES",
                sellerCentralURL: "https://sellercentral-europe.amazon.com",
                flagURL: "https://flagcdn.com/es.svg"
            },
            {
                country: "United Kingdom",
                marketplaceId: "A1F83G8C2ARO7P",
                countryCode: "UK",
                sellerCentralURL: "https://sellercentral-europe.amazon.com",
                flagURL: "https://flagcdn.com/gb.svg"
            },
            {
                country: "France",
                marketplaceId: "A13V1IB3VIYZZH",
                countryCode: "FR",
                sellerCentralURL: "https://sellercentral-europe.amazon.com",
                flagURL: "https://flagcdn.com/fr.svg"
            },
            {
                country: "Belgium",
                marketplaceId: "AMEN7PMS3EDWL",
                countryCode: "BE",
                sellerCentralURL: "https://sellercentral.amazon.com.be",
                flagURL: "https://flagcdn.com/be.svg"
            },
            {
                country: "Netherlands",
                marketplaceId: "A1805IZSGTT6HS",
                countryCode: "NL",
                sellerCentralURL: "https://sellercentral.amazon.nl",
                flagURL: "https://flagcdn.com/nl.svg"
            },
            {
                country: "Germany",
                marketplaceId: "A1PA6795UKMFR9",
                countryCode: "DE",
                sellerCentralURL: "https://sellercentral-europe.amazon.com",
                flagURL: "https://flagcdn.com/de.svg"
            },
            {
                country: "Italy",
                marketplaceId: "APJ6JRA9NG5V4",
                countryCode: "IT",
                sellerCentralURL: "https://sellercentral-europe.amazon.com",
                flagURL: "https://flagcdn.com/it.svg"
            },
            {
                country: "Sweden",
                marketplaceId: "A2NODRKZP88ZB9",
                countryCode: "SE",
                sellerCentralURL: "https://sellercentral.amazon.se",
                flagURL: "https://flagcdn.com/se.svg"
            },
            {
                country: "South Africa",
                marketplaceId: "AE08WJ6YKNBMC",
                countryCode: "ZA",
                sellerCentralURL: "https://sellercentral.amazon.co.za",
                flagURL: "https://flagcdn.com/za.svg"
            },
            {
                country: "Poland",
                marketplaceId: "A1C3SOZRARQ6R3",
                countryCode: "PL",
                sellerCentralURL: "https://sellercentral.amazon.pl",
                flagURL: "https://flagcdn.com/pl.svg"
            },
            {
                country: "Egypt",
                marketplaceId: "ARBP9OOSHTCHU",
                countryCode: "EG",
                sellerCentralURL: "https://sellercentral.amazon.eg",
                flagURL: "https://flagcdn.com/eg.svg"
            },
            {
                country: "Turkey",
                marketplaceId: "A33AVAJ2PDY3EV",
                countryCode: "TR",
                sellerCentralURL: "https://sellercentral.amazon.com.tr",
                flagURL: "https://flagcdn.com/tr.svg"
            },
            {
                country: "Saudi Arabia",
                marketplaceId: "A17E79C6D8DWNP",
                countryCode: "SA",
                sellerCentralURL: "https://sellercentral.amazon.sa",
                flagURL: "https://flagcdn.com/sa.svg"
            },
            {
                country: "United Arab Emirates",
                marketplaceId: "A2VIGQ35RCS4UG",
                countryCode: "AE",
                sellerCentralURL: "https://sellercentral.amazon.ae",
                flagURL: "https://flagcdn.com/ae.svg"
            },
            {
                country: "India",
                marketplaceId: "A21TJRUUN4KGV",
                countryCode: "IN",
                sellerCentralURL: "https://sellercentral.amazon.in",
                flagURL: "https://flagcdn.com/in.svg"
            },
        ]
    },
    {
        category: "Far East",
        marketplaces: [
            {
                country: "Singapore",
                marketplaceId: "A19VAU5U5O7RUS",
                countryCode: "SG",
                sellerCentralURL: "https://sellercentral.amazon.sg",
                flagURL: "https://flagcdn.com/sg.svg"
            },
            {
                country: "Australia",
                marketplaceId: "A39IBJ37TRP1C6",
                countryCode: "AU",
                sellerCentralURL: "https://sellercentral.amazon.com.au",
                flagURL: "https://flagcdn.com/au.svg"
            },
            {
                country: "Japan",
                marketplaceId: "A1VC38T7YXB528",
                countryCode: "JP",
                sellerCentralURL: "https://sellercentral.amazon.co.jp",
                flagURL: "https://flagcdn.com/jp.svg"
            },
        ]
    },
];

