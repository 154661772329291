import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Auth0Provider} from "@auth0/auth0-react";
import authorizationRoutes from './constants/authorizationRoutes';

// eslint-disable-next-line no-undef
Office.onReady(() => {
	const root = ReactDOM.createRoot(document.getElementById('root'));

	var domainVar = authorizationRoutes.domain;
	var clientId = authorizationRoutes.clientId;


	root.render(
		<Auth0Provider
			domain={domainVar} 
			clientId={clientId}
			authorizationParams={{
				redirect_uri: window.location.origin
			}}>
			<App></App>
		</Auth0Provider>
	);
});

