
function LoggedOut() {

console.log("LOGOUT COMPLETE")
        try{
            // eslint-disable-next-line no-undef
            Office.onReady(() => {
              // eslint-disable-next-line no-undef
              Office.context.ui.messageParent(JSON.stringify({}), { targetOrigin: "*" });
            });
        }catch(ex){
          console.log(ex);
        }
        return<div>LoggedOut</div>
}

export default LoggedOut;