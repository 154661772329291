import {useAuth0} from "@auth0/auth0-react";
import {buildAmazonOAuthUrl} from "../../amazon/amazonUrlBuilder";

function AmazonLogin() {
    const {
        isAuthenticated,
        isLoading
    } = useAuth0();

    if(isLoading)
    {
        return <div>Loading...</div>
    }

    if(!isAuthenticated){
        return <div>Not Authenticated</div>
    }

    const searchParams
        = new URLSearchParams(document.location.search)

    let marketPlaceId
        = searchParams.get("mpid")

    let state
        = searchParams.get("state")


    if(state === undefined ||
        state === null ||
        marketPlaceId === null ||
        marketPlaceId === undefined) {
        return (<div>Error...</div>)
    }

    window.location.href = buildAmazonOAuthUrl(marketPlaceId,
        true,
        state);
}

export default AmazonLogin;