import {useAuth0} from "@auth0/auth0-react";

function AmazonRedirect() {
    const {
        isAuthenticated,
        isLoading
    } = useAuth0();


    if(isLoading)
    {
        return <div>Loading...</div>
    }

    if(!isAuthenticated){
        return <div>Not Authenticated</div>
    }

    const searchParams
        = new URLSearchParams(document.location.search)

    let state
        = searchParams.get("state")

    let spId
        = searchParams.get("selling_partner_id")

    let lwa
        = searchParams.get("spapi_oauth_code")

    let data = {
        type: 'amazonRedirect',
        authCode: lwa,
        sellingPartnerId : spId,
        state: state,
        redirectUri: document.location.href
    };

    // eslint-disable-next-line no-undef
    Office.onReady(() => {
        // eslint-disable-next-line no-undef
        Office.context.ui.messageParent(JSON.stringify(data), { targetOrigin: "*" });
    });
}

export default AmazonRedirect;