import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoggedIn from './LoggedIn';
import defaultAuthParams from '../constants/defaultAuthorizationParams';

function LogInWrapper() {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error
  } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        authorizationParams: defaultAuthParams
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isAuthenticated) {
    return <LoggedIn />;
  }

  // This will never be reached because the loginWithRedirect call will redirect the user
  return null;
}

export default LogInWrapper;
