import {useAuth0} from "@auth0/auth0-react";
import defaultAuthParams from "../constants/defaultAuthorizationParams";

function LoggedIn() {
	const {
		getAccessTokenSilently
	} = useAuth0();


	getAccessTokenSilently({
		authorizationParams: defaultAuthParams
	}).then(e => {
		messageAccessToken(e);
	});

	return <>Logged In</>;

	
}

function messageAccessToken(accessToken) {
	var data = {
		accessToken: accessToken
	};

	try {
		// eslint-disable-next-line no-undef
		Office.onReady(() => {
		// eslint-disable-next-line no-undef
		Office.context.ui.messageParent(JSON.stringify(data), { targetOrigin: "*" });
		});
	} catch (e) {
		
	}
}

export default LoggedIn;