import './App.css';
import LogInWrapper from "./components/LogInWrapper";
import LogOutWrapper from "./components/LogOutWrapper";
import AmazonRedirect from "./components/Amazon/AmazonRedirect";
import AmazonLogin from "./components/Amazon/AmazonLogin";
import React from "react";

function App() {

	const searchParams
	 = new URLSearchParams(document.location.search)
	
	let action 
	 = searchParams.get("action"); 
	
	if (action === 'logout') {
		return <LogOutWrapper />
	}
	else if(action === "amazon-login") {
      	return <AmazonLogin />
	}
	else if(action === "amazon-redirect") {
		return <AmazonRedirect />
	}
	else if(action === "from-amazon") {
	  	return <div>Not Implemented</div>
	}
	else{
		return <LogInWrapper/>;
	}

}

export default App;
